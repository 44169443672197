import Vue from "vue";
import VueRouter from "vue-router";
import Seguimiento from "../App.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "",
		component: Seguimiento,
	},
	{
		path: "/Alsea",
		name: "Alsea",
		component: Seguimiento,
	},
	{
		path: "/BurgerKing",
		name: "BurgerKing",
		component: Seguimiento,
	},
	{
		path: "/Melt",
		name: "Melt",
		component: Seguimiento,
	},
	{
		path: "/Bata",
		name: "Bata",
		component: Seguimiento,
	},
	{
		path: "/Aloprint",
		name: "Aloprint",
		component: Seguimiento,
	},
	{
		path: "/Forus",
		name: "Forus",
		component: Seguimiento,
	},
	{
		path: "/CruzVerde",
		name: "CruzVerde",
		component: Seguimiento,
	},
	{
		path: "/Claro",
		name: "Claro",
		component: Seguimiento,
	},

	{
		path: "/2bak",
		name: "2bak",
		component: Seguimiento,
	},

	{
		path: "/travel",
		name: "travel",
		component: Seguimiento,
	},


	
	{
		path: "/Degasa",
		name: "Degasa",
		component: Seguimiento,
	},
	{
		path: "/Maicao",
		name: "Maicao",
		component: Seguimiento,
	},
	{
		path: "/Wom",
		name: "wom",
		component: Seguimiento,
	},
	{
		path: "/consulta",
		name: "Consulta",
		component: Seguimiento,
	},
	{
		path: "/Enviame",
		name: "Enviame",
		component: Seguimiento,
	},
	{
		path: "/Transbank",
		name: "Transbank",
		component: Seguimiento,
	},
	{
		path: "/AlmacenGuru",
		name: "ALMACENGURU",
		component: Seguimiento,
	},
	{
		path: "/ALMACÉN%20GURÚ",
		name: "ALMACÉN%20GURÚ",
		component: Seguimiento,
		
	},
	{
		path: "/Liquidos.cl",
		name: "LIQUIDOS.CL",
		component: Seguimiento,
		
	},
	
	{
		path: "/Mundo",
		name: "Mundo",
		component: Seguimiento,
	},

	{
		path: "/Corona",
		name: "Corona",
		component: Seguimiento,
	},

	{
		path: "/Unimarc",
		name: "Unimarc",
		component: Seguimiento,
	},

	{
		path: "/DAC",
		name: "DAC",
		component: Seguimiento,
	},
	{
		path: "/DAC%20Cordillera",
		name: "DAC",
		component: Seguimiento,
	},
	{
		path: "/BEES",
		name: "BEES",
		component: Seguimiento,
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

export default router;
