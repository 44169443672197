import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
//import axios from "axios";
import FormatFunctions from "./mixins/FormatFunctions";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "../src/assets/rayostyles.css";
import router from "./router";
import VueExpandableImage from 'vue-expandable-image'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

//axios.defaults.baseURL = "https://api.tookanapp.com";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueExpandableImage)
Vue.use(VuetifyGoogleAutocomplete, {
	apiKey: 'AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`	
	language: 'sp',  // Optional
	installComponents: true, // Optional (default: true) - false, if you want to locally install components
	vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
  });

Vue.mixin(FormatFunctions); //import various function to all components

new Vue({
	vuetify,
	router,
	render: (h) => h(App)
}).$mount("#app");
